import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { Injector, PLATFORM_ID } from '@angular/core';
import { Location, isPlatformServer } from '@angular/common';
import { DOCUMENT } from '@angular/common';


import { MiscUtil } from '../util/misc.util';

import { BtEvent, EventService } from './event.service';
import { HookService } from './hook.service';
import { DATAID, DataService } from './data.service';
import { UtilService } from './util.service';
import { WebService } from './web.service';
import { UIService } from './ui.service';
import { OrderService } from './order.service';
import { StyleService } from './style.service';
import { ScriptService } from './script.service';
import { AppAdminService } from './app-admin.service';
import { AppCommonService } from './app-common.service';
import { FormService } from './form.service';
import { ApiService } from './api.service';
import { TrackingService } from './tracking.service';
import { KlaroService } from '../plugins/klaro/services/klaro.service';

export const BT_INIT_CONFIG = new InjectionToken<any>('BT_INIT_CONFIG', {
  providedIn: 'root',
  factory: () => undefined
});

@Injectable({
  providedIn: 'root'
})
export class BooktechAppService {
  name = "BooktechAppService"

  api:ApiService;
  ds:DataService;
  es:EventService;
  os:OrderService;
  ui:UIService;
  us:UtilService;
  ws:WebService;
  style:StyleService;
  script:ScriptService;
  aas:AppAdminService;
  acs:AppCommonService;
  fs:FormService;
  hs:HookService;
  ts:TrackingService;

  ks:KlaroService|undefined;

  env:any;
  settings:any;
  envprod:boolean = false;
  envtest:boolean = true;
  envdev:boolean = true;
  standalone:boolean = false;
  // ssr:boolean = false;

  ssr :boolean = false;
  nossr :boolean = true;

  appLogin:any = { success: false }

  public DOC = inject(DOCUMENT);
  public LOC = inject(Location);

  constructor(@Inject(BT_INIT_CONFIG) public initConfig: any) {
    // console.log("env: ", env)

  
    this.env = initConfig.env;
    this.settings = this.env.settings;
    this.standalone = !!this.settings.standalone;

    this.envprod = this.settings.env;
    this.envtest = !this.envprod;

    if(this.envtest)  console.log(MiscUtil.getLogText("BooktechAppService.constructor, initConfig: "), initConfig);

    let platformId = inject(PLATFORM_ID);
    this.ssr = isPlatformServer(platformId);
    this.nossr = !this.ssr;

    if(this.envtest)  console.log(MiscUtil.getLogText("platformId: " + platformId + "; ssr: " + this.ssr));

    if(this.envtest)  console.log(MiscUtil.getLogText("BooktechAppService.constructor, settings: "), this.settings);


    // let win:any = window;
    this.envdev = this.nossr && window.location.hostname === "192.168.1.81";
    

    if(this.envtest)  console.log(MiscUtil.getLogText("LOC, path " + this.LOC.path() 
      // + ", state: " +this.LOC.getState()
      // + ", normalize: " +this.LOC.normalize()
      ));



    
      this.es = new EventService(this);
      this.hs = new HookService(this);

      this.api = new ApiService(this);
      this.ds = new DataService(this);
  
      this.aas = new AppAdminService(this);
      this.acs = new AppCommonService(this);
      this.os = new OrderService(this);
      this.ui = new UIService(this);
      this.us = new UtilService(this);
      this.ws = new WebService(this);
      this.style = new StyleService(this);
      this.script = new ScriptService(this);
      this.fs = new FormService(this);
      this.ts = new TrackingService(this);
  
      if(this.envtest) console.log("BooktechAppService, this.settings.plugins?.klaro?.enabled: " + this.settings.plugins?.klaro?.enabled);
      if (this.settings.plugins?.klaro?.enabled && this.nossr) {
        if(this.envtest) console.log("BooktechAppService, this.settings.plugins?.klaro?.enabled: ");
        this.ks = new KlaroService(this);
      }
  
      this.es.on(BtEvent.APP_INIT, this.initializeApp); // denne blir aldri kallt
  
  
      this.ds.get(DATAID.APP_LOGIN).subscribe((appLogin:any) => {
        if(this.envtest) console.log("BooktechAppService, subscribe:APP_LOGIN: ", appLogin);
        this.appLogin = appLogin;
      });
  
      if(this.envtest) console.log("BooktechAppService.constructor DONE, standalone: " + this.standalone);
  }

  initializeApp() {
    if(this.envtest) console.log("BooktechAppService.initializeApp");
  }

}
