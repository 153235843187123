
<p>lib-template-page works!</p>


btn:
<button nz-button>btn</button>
<div></div>

lib-comp:
<lib-template-component></lib-template-component>


