import { Component, inject } from '@angular/core';

import { LibModulesModule } from '../../components/lib-modules.module';
import { BooktechAppService } from "../../services/booktech-app.service";

@Component({
  selector: 'lib-template-page',
  standalone: true,
  imports: [ LibModulesModule ],
  templateUrl: './lib-template-page.page.html',
  styleUrl: './lib-template-page.page.scss'
})
export class LibTemplatePagePage {

  bas = inject(BooktechAppService);

  constructor() {
    if (this.bas.envtest) console.log("LibTemplatePagePage.constructor")
  }

  ngOnInit() {
    if (this.bas.envtest) console.log("LibTemplatePagePage.ngOnInit")

  }

}
