/* pipes.modules.ts */
import { NgModule, ModuleWithProviders } from '@angular/core';

import { ACTransPipe } from './actrans.pipe';
import { MessageTransPipe } from './messagetrans.pipe';
import { StrPipe } from './str.pipe';
import { StringifyPipe } from './stringify.pipe';
import { IntPipe } from './int.pipe';
import { TrustHtmlPipe } from './trusthtml.pipe';
import { FilterPipe } from './ngx-filter.pipe';
import { SortByPipe } from './sortBy.pipe';

import { NfPipe } from './nf.pipe';
import { NfiPipe } from './nfi.pipe';
import { JoinPipe } from './join.pipe';


@NgModule({
  declarations: [

  ],
  imports: [
    ACTransPipe, 
    MessageTransPipe,
    StrPipe, 
    StringifyPipe,
    IntPipe, 
    TrustHtmlPipe, 
    FilterPipe,

    NfPipe, 
    NfiPipe, 
    SortByPipe, 
    JoinPipe,
  ],
  exports: [
    ACTransPipe, 
    MessageTransPipe,
    StrPipe, 
    StringifyPipe,
    IntPipe, 
    TrustHtmlPipe, 
    FilterPipe,
    NfPipe, 
    NfiPipe, 
    SortByPipe,
    JoinPipe,
  ]
})
export class BtPipesModule {

}
